import { Dropdown, Text, Tooltip, Button, Banner } from '@loadsmart/miranda-react';
import LsLogo from 'assets/images/ls_logo_ma.png'
import ReportHeaderComponent from './components/reportHeader/ReportHeader';
import Header from './components/header/Header';
import ReportComponent from './components/report/Report';
import { RequestType } from '../domain/RequestType';
import { VscGraph } from "react-icons/vsc";
import { PiArrowLineLeftBold, PiArrowLineRightBold } from "react-icons/pi";
import { useState, useEffect } from 'react'
import ZendeskDialog from './components/zendesk/TryNowZendeskDialog'
import { useRequestZendesk } from './hooks/useRequestZendesk'
import { AnalyticsEvent } from 'core/analytics/events'
import { useAnalytics } from 'core/analytics/useAnalytics'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
 
import {
    StyledActionDiv,
    StyledDropDown,
    StyledLSLogoImg,
    StyledPageContainerDiv,
    StyledReportWrapper,
    StyledSideMenuDiv,
    StyledSubTitleDiv
} from './DemoPage.styles'

interface Report {
    report_name: string;
    group_id: string;
    report_id: string;
    embed_token: [string, string];
}

const TRACK_EVENT_EXPIRY_MS = 1 * 60 * 1000;

const Demo = () => {
    const [isReportLoading, setIsReportLoading] = useState(true)
    const [reports, setReports] = useState<Report[]>([])
    const [selectedReport, setSelectedReport] = useState<Report>()
    const [isInitialLoad, setIsInitialLoad] = useState(true)

    const [isCollapsed, setIsCollapsed] = useState(false)

    //mixpanel init
    const { track } = useAnalytics();

    const navigate = useNavigate()

    const [showDemoBanner, setShowDemoBanner] = useState(true);

    const handleDemoBannerDismiss = () => {
        setShowDemoBanner(false);
    };

    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        const isMobile = /Mobi/i.test(userAgent);
        
        if (isMobile) {
          navigate('/mobile-not-supported');
        }
      }, []);

      function shouldTrackEvent() {
        const lastTracked = localStorage.getItem('lastTrackedDemoEvent');
        if (!lastTracked) return true;
    
        const now = Date.now();
        return now - parseInt(lastTracked, 10) > TRACK_EVENT_EXPIRY_MS;
    }

      async function getPBIReportDemo() {
        try {
            const response = await fetch(`${import.meta.env.VITE_BASE_API_URL}/pbi_report_demo`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setReports(data);
            setSelectedReport(data[0]);
            setIsReportLoading(false);
            setIsInitialLoad(false);

            if (shouldTrackEvent()) {
                track(AnalyticsEvent.OpenDemo);
                localStorage.setItem('lastTrackedDemoEvent', Date.now().toString());
            } 
        } catch (error) {
            console.error('Failed to fetch PBI report demo:', error);
            throw error;
        }
    }

    useEffect(() => {
        getPBIReportDemo();
    }, []);

    useEffect(() => {
        if (!isInitialLoad && selectedReport) {
            setIsReportLoading(true);
            const timer = setTimeout(() => {
                setIsReportLoading(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [selectedReport, isInitialLoad]);

    const [requestType, setRequestType] = useState<RequestType>()
    const [showRequestDialog, setShowRequestDialog] = useState(false)

    const BannerWrapper = styled.div`
        text-align: left;
        position: absolute;
        width: 85%;
        margin-left: 15%;
        z-index: 1000;
    `
    const DialogFeedbackType = {
        NONE: '',
        SUCCESS: 'success',
        FAILED: 'failed',
    }
    const [showDialogFeedback, setShowDialogFeedback] = useState(DialogFeedbackType.NONE)
    const { hasError, hasSuccess, isSendingRequest, requestZendesk } = useRequestZendesk()

    useEffect(() => {
        if (hasError) {
            setShowDialogFeedback(DialogFeedbackType.FAILED)
            setShowRequestDialog(false)
        }

        if (hasSuccess) {
            setShowDialogFeedback(DialogFeedbackType.SUCCESS)
            setShowRequestDialog(false)
        }
    }, [hasError, hasSuccess])

    const toggleZendeskDialog = (requestType: RequestType) => {
        setRequestType(requestType)
        setShowRequestDialog(true)
    }

    const handleOnSendFeedback = async (name: string, company: string, email: string, phone: string) => {
        if (requestType) {
            requestZendesk(requestType, name, company, email, phone)
        }
        setShowRequestDialog(false)
    }

    const handleDismiss = () => {
        setShowDialogFeedback(DialogFeedbackType.NONE)
    }

    const toggleMenuCollapse = () => {
        setIsCollapsed(!isCollapsed)
    }

    const StyledDropdownTrigger = styled('m-dropdown-trigger')`
        display: flex;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        cursor: pointer;

        .text {
            flex: 0 1 92%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            pointer-events: none;
        }

        .arrow {
            flex: 0 0 8%;
            display: flex;
            justify-content: flex-end;
            padding-left: 8px;
            pointer-events: none;
        }
    `;

    const [isReportOverflowing, setIsReportOverflowing] = useState(false);

    const isTextOverflowing = (text: string, comboboxWidth: number): boolean => {
        const averageCharWidth = 9.8;
        const maxChars = Math.floor(comboboxWidth / averageCharWidth);
        return text.length > maxChars;
    };

    const getDropdownWidth = (): number => {
        const dropdownElement = document.querySelector('m-dropdown-trigger[role="button"]');
        if (!dropdownElement) {
            return 0;
        }
        return dropdownElement.getBoundingClientRect().width;
    };

    useEffect(() => {
        const comboboxWidth = getDropdownWidth();
        if (selectedReport?.report_name) {
            setIsReportOverflowing(isTextOverflowing(selectedReport.report_name, comboboxWidth));
        }
    }, [selectedReport]);

    return (
        <StyledPageContainerDiv>
            {showDialogFeedback === DialogFeedbackType.SUCCESS && (
                <BannerWrapper>
                    <Banner variant="success" onDismiss={handleDismiss} dismissible>
                        <Banner.Title>Success</Banner.Title>
                        <Banner.Description>
                            Your request has been sent. Soon we'll get in touch.
                        </Banner.Description>
                    </Banner>
                </BannerWrapper>
            )}
            {showDialogFeedback === DialogFeedbackType.FAILED && (
                <BannerWrapper>
                    <Banner variant="danger" onDismiss={handleDismiss} dismissible>
                        <Banner.Title>Your request was not sent.</Banner.Title>
                        <Banner.Description>
                            Please contact Loadsmart team to handle the request.
                        </Banner.Description>
                    </Banner>
                </BannerWrapper>
            )}
            <StyledSideMenuDiv className={isCollapsed ? 'collapsed' : ''}>
                    <Button variant='icon' size='large' onClick={toggleMenuCollapse} style={{ color: '#fbd709',  marginLeft: 'calc(100% - 25px)', marginTop: '7%'}}>
                        {isCollapsed ? <PiArrowLineRightBold size={'25'} color='#005454	'/> : <PiArrowLineLeftBold size={'25'} color='#005454	'/>}
                    </Button>
                    {!isCollapsed && (
                        <>
                        <StyledLSLogoImg src={LsLogo} alt="LS Logo" />
                        <StyledSubTitleDiv>
                            <Text color="color-neutral-60" variant="body-md-bold">
                                REPORTS
                            </Text>
                        </StyledSubTitleDiv>
                        <div>
                            {isReportOverflowing ? (
                                <Tooltip
                                    content="Report"
                                    message={selectedReport?.report_name}
                                    trigger="hover"
                                >
                                    <StyledDropDown size="default">
                                        <StyledDropdownTrigger>
                                            <span className="text">
                                                {selectedReport?.report_name || 'Loading...'}
                                            </span>
                                        </StyledDropdownTrigger>
                                        <Dropdown.Menu>
                                            {reports.map((report, index) => (
                                                <Dropdown.Item 
                                                    key={index}
                                                    onClick={() => {
                                                        if (report.report_id !== selectedReport?.report_id) {
                                                            setSelectedReport(report);
                                                        }
                                                    }}
                                                >
                                                    {report.report_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </StyledDropDown>
                                </Tooltip>
                            ) : (
                                <StyledDropDown size="default">
                                    <StyledDropdownTrigger>
                                        <span className="text">
                                            {selectedReport?.report_name || 'Loading...'}
                                        </span>
                                    </StyledDropdownTrigger>
                                    <Dropdown.Menu>
                                        {reports.map((report, index) => (
                                            <Dropdown.Item 
                                                key={index}
                                                onClick={() => {
                                                    if (report.report_id !== selectedReport?.report_id) {
                                                        setSelectedReport(report);
                                                    }
                                                }}
                                            >
                                                {report.report_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </StyledDropDown>
                            )}
                        </div>
                        <StyledSubTitleDiv>
                            <Text color="color-neutral-60" variant="body-md-bold">
                                INTEGRATIONS
                            </Text>
                        </StyledSubTitleDiv>
                        <StyledActionDiv>
                            <Tooltip content="Integrations Overview" message="Not available on demo mode" trigger="hover">
                                <Text
                                    color="color-neutral-10"
                                    variant="body-md-bold"
                                >
                                    Integrations Overview
                                </Text>
                            </Tooltip>
                        </StyledActionDiv>
                        </>
                )}
            </StyledSideMenuDiv>
            <StyledReportWrapper className={isCollapsed ? 'collapsed' : ''}>
                <Header />
                <ReportHeaderComponent 
                    reportName={selectedReport?.report_name || ''} 
                />

                <ReportComponent
                    groupId={selectedReport?.group_id || ''}
                    reportId={selectedReport?.report_id || ''}
                    reportName={selectedReport?.report_name}
                    accessToken={selectedReport?.embed_token[0] || ''}
                    isReportLoading={isReportLoading}
                />
                { showDemoBanner && (
                <Banner variant="warning" onDismiss={handleDismiss} dismissible>
                    <Banner.Icon><VscGraph color='#005454' size='big' /></Banner.Icon>
                    <Banner.Title>Liked something here? Didn't find exactly what you were looking for?</Banner.Title>
                    <Banner.Description>Take your insights further with custom reports. Unlock the full potential of your data by partnering with our solutions team to create on-demand tailored metrics, KPIs, views, filters, and charts to meet your specific needs.</Banner.Description>
                    <Banner.Description>Explore our custom automated workflows for seamless integration and regularly scheduled reports delivered when you need them.</Banner.Description>
                    <Banner.Actions>
                        <Banner.ActionPrimary onClick={() => toggleZendeskDialog(RequestType.TryItNow)}>
                            Contact us today for a quote
                        </Banner.ActionPrimary>
                        <Banner.ActionSecondary onClick={handleDemoBannerDismiss}>
                            Dismiss
                        </Banner.ActionSecondary>
                    </Banner.Actions>
                </Banner>
                )}
            </StyledReportWrapper>
            <ZendeskDialog
                open={showRequestDialog}
                disabled={isSendingRequest}
                onCancelRequest={() => setShowRequestDialog(false)}
                onSendRequest={handleOnSendFeedback}
            />
        </StyledPageContainerDiv>
    )
}

export default Demo;
