import { useCallback, useState } from 'react'

import { RequestStatus } from 'core/domain/Request'
import { PBIReport } from 'managed-analytics/domain/PBIReport'
import { getPBIReports } from 'managed-analytics/use-cases/getPBIReports'
import { signOut } from 'core/utils/signout';

export function usePBIReports() {
  const [reports, setReports] = useState<Array<PBIReport>>([])
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial)

  const isLoading = status === RequestStatus.Pending

  

  const getReports = useCallback(async (config?: AbortController) => {
    setStatus(RequestStatus.Pending)

    try {
      const reports = await getPBIReports(config)
      console.log('reports', reports)
      setReports(reports)
      setStatus(RequestStatus.Done)
    } catch (error: any) {
      if (config?.signal.aborted) return
      if (error.status = '401'){
        signOut();
      } 
      else{
        setStatus(RequestStatus.Error)
      }
      
      setReports([])

      throw error
    }
  }, [])

  return {
    isLoading,
    status,
    reports,
    getReports,
  }
}
