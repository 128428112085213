import { useCallback, useEffect, useRef, useState } from 'react'

import { models, Report } from 'powerbi-client'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { Dropdown, Text, Banner, Button, Tooltip } from '@loadsmart/miranda-react'

import LsLogo from 'assets/images/ls_logo_ma.png'
import { TOKEN_STORAGE_USER_KEY, LOGIN_STORAGE_USER } from 'auth/domain/User'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import Header from 'core/ui/components/header/Header'
import ReportHeaderComponent from './components/reportHeader/ReportHeader';
import { PBIReport, PBIToken } from 'managed-analytics/domain/PBIReport'
import { RequestType } from 'managed-analytics/domain/RequestType'
import IntegrationComponent from 'managed-analytics/ui/components/integration/Integration'
import ReportComponent from 'managed-analytics/ui/components/report/Report'
import { useIntegrations } from 'managed-analytics/ui/hooks/useIntegrations'
import { usePBIReports } from 'managed-analytics/ui/hooks/usePBIReports'
import { AnalyticsEvent } from 'core/analytics/events'
import { useAnalytics } from 'core/analytics/useAnalytics'
import { useInitApplication } from 'core/ui/hooks/useInitApplication'
import { PiArrowLineLeftBold, PiArrowLineRightBold } from "react-icons/pi";

import ZendeskDialog from './components/zendesk/ZendeskDialog'
import { usePBIToken } from './hooks/usePBIToken'
import { useRequestZendesk } from './hooks/useRequestZendesk'
import { Icon } from '@loadsmart/miranda-react'
import { VscGraph } from 'react-icons/vsc'

import {
  StyledActionDiv,
  StyledDropDown,
  StyledLSLogoImg,
  StyledPageContainerDiv,
  StyledReportWrapper,
  StyledSideMenuDiv,
  StyledSubTitleDiv,
} from './ManagedAnalyticsPage.styles'

enum DialogFeedbackType {
  NONE = '',
  SUCCESS = 'success',
  FAILED = 'failed',
}

const BannerWrapper = styled.div`
  text-align: left;
`

const buildReportConfig = (report: PBIReport, pbiToken: PBIToken) => {
  const { groupId, reportId } = report

  const config = {
    accessToken: pbiToken.embedToken,
    embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`,
    tokenType: models.TokenType.Embed,
    type: 'report',
  }

  return config
}

interface UserLogin {
  client: string
  first_name: string
  last_name: string
  email: string
  issuer: string
}

const ManagedAnalytics = () => {
  const currentReport = useRef<Report>()
  const [selectedReport, setSelectedReport] = useState<PBIReport | undefined>()
  const [requestType, setRequestType] = useState<RequestType>()
  const [showRequestDialog, setShowRequestDialog] = useState(false)
  const [showDialogFeedback, setShowDialogFeedback] = useState(DialogFeedbackType.NONE)
  const [showIntegrations, setShowIntegrations] = useState(false)
  const { hasError, hasSuccess, isSendingRequest, requestZendesk } = useRequestZendesk()
  const { getReports, isLoading: isReportsLoading, reports } = usePBIReports()
  const { getPBIToken, resetPBIToken, isLoading: isTokenLoading, pbiToken } = usePBIToken()
  const { getIntegration, isLoadingIntegrations, integrations } = useIntegrations()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const storedParams = JSON.parse(localStorage.getItem('fiai_redirect_params') || '{}');
  console.log('fiai params:', storedParams);


  //mixpanel init
  const { track } = useAnalytics();
  const [freshRender, setFreshRender] = useState(true)
  useInitApplication()

  const initApplication = () => {
    console.log(reports)
    if (reports) setSelectedReport(reports[0])
    if (reports[0] && freshRender){  
      const mixpanel_custom_props = {
        "Report Name": reports[0].reportName, 
        "Dataset Id": reports[0].datasetId,
        "Dataset Group Id": reports[0].groupId,
        "PBI User Id": reports[0].rlsFilter,
        "PBI User Type": reports[0].customerType,
        "Role Name": reports[0].roleName,
        "Default Report": true
      }
      track(AnalyticsEvent.OpenReport, mixpanel_custom_props);
      track(AnalyticsEvent.InitApplication);
      setFreshRender(false)
    }
  }

  const handleIntegrationsOverviewClick = () => {
    setShowIntegrations(true)
    track(AnalyticsEvent.OpenIntegrationsOverview);
  }

  const handleDemoAllReportsClick = () => {
      window.open('/demo', '_blank');
  }

  const handleDismiss = () => {
    setShowDialogFeedback(DialogFeedbackType.NONE)
  }

  const handleOnSendFeedback = (description: string) => {
    if (!requestType) return
    const reportName:any = selectedReport?.reportName
    requestZendesk(requestType, reportName, description)
  }

  const [showRefreshButton, setShowRefreshButton] = useState(false);

  const handleRefreshReport = () => {
    setShowRefreshButton(false); 
    localStorage.removeItem('fiai_redirect_params');
    setSelectedReport(undefined); 
    setTimeout(() => {
      setSelectedReport(selectedReport);
    }, 500);
  };
  
  useEffect(() => {
    const storedParams = JSON.parse(localStorage.getItem('fiai_redirect_params') || '{}');
  
    if (storedParams.reportName && reports.length > 0) {
      console.log("Checking for matching report after reports are loaded...");
  
      const matchingReport = reports.find(report => report.reportName === storedParams.reportName);
  
      if (matchingReport) {
        setTimeout(() => {
          setSelectedReport(matchingReport);
          setShowRefreshButton(true);
          console.log(`Final report selected: ${matchingReport.reportName}`);
        }, 500);

      } else {
        console.warn(`No matching report found for: ${storedParams.reportName}`);
      }
    }
  }, [reports]);
  
  
  useEffect(() => {
    if (hasError) {
      setShowDialogFeedback(DialogFeedbackType.FAILED)
      setShowRequestDialog(false)
    }

    if (hasSuccess) {
      setShowDialogFeedback(DialogFeedbackType.SUCCESS)
      setShowRequestDialog(false)
    }
  }, [hasError, hasSuccess])

  const toggleZendeskDialog = (requestType: RequestType) => {
    setRequestType(requestType)
    setShowRequestDialog(true)
  }

  const storage = new LocalStorageClient()
  const userAccessToken = storage.get(TOKEN_STORAGE_USER_KEY)

  const navigate = useNavigate()

  useEffect(() => {
    if (!userAccessToken) navigate('/')
  }, [navigate, userAccessToken])

  useEffect(() => {
    getReports()
  }, [getReports])

  useEffect(() => {
    initApplication()
  }, [track, reports])

  useEffect(() => {
    const updateToken = async () => {
      await resetPBIToken()
      getPBIToken(selectedReport)
    }

    updateToken()
  }, [getPBIToken, resetPBIToken, selectedReport])

  useEffect(() => {
    getIntegration()
  }, [getIntegration])

  const handleDropdownItemClick = useCallback(
    (report: PBIReport) => {
      const mixpanel_custom_props = {
        "Report Name": report.reportName, 
        "Dataset Id": report.datasetId,
        "Dataset Group Id": report.groupId,
        "PBI User Id": report.rlsFilter,
        "PBI User Type": report.customerType,
        "Role Name": report.roleName,
        "Default Report": false
      }

      setShowRefreshButton(false);
      localStorage.removeItem('fiai_redirect_params');
      setSelectedReport(report)
      setShowIntegrations(false)
      
      if (!pbiToken) return

      track(AnalyticsEvent.OpenReport, mixpanel_custom_props);
      const { service, element } = currentReport.current ?? {}

      if (!service || !element) return

      service.reset(element)
      service.embed(element, buildReportConfig(report, pbiToken))
    },
    [pbiToken, track]
  )

  const toggleMenuCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  const uniqueRoles = Array.from(new Set(reports.map(report => report.roleName).filter(role => role != null)))
  const isSingleRole = uniqueRoles.length === 1
  const filteredReports = reports.filter(report => report.roleName === selectedReport?.roleName)
  const userLogin: UserLogin = storage.get(LOGIN_STORAGE_USER) as UserLogin

  useEffect(() => {
    if (uniqueRoles.length > 0 && !selectedReport) {
      const defaultRole = reports.find((report) => report.roleName === uniqueRoles[0]);
      if (defaultRole) {
        setSelectedReport(defaultRole);
      }
    }
  }, [uniqueRoles, reports, selectedReport]);
  
  useEffect(() => {
    if (filteredReports.length > 0 && !selectedReport) {
      setSelectedReport(filteredReports[0]);
    }
  }, [filteredReports, selectedReport]);

  const StyledDropdownTrigger = styled('m-dropdown-trigger')`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;

    .text {
      flex: 0 1 92%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      pointer-events: none;
    }

    .arrow {
      flex: 0 0 8%;
      display: flex;
      justify-content: flex-end;
      padding-left: 8px;
      pointer-events: none;
    }
`;

const isTextOverflowing = (text: string, comboboxWidth: number): boolean => {
  const averageCharWidth = 9.8;
  console.log(comboboxWidth)
  console.log(text.length)
  const maxChars = Math.floor(comboboxWidth / averageCharWidth);
  return text.length > maxChars;
};

const [isProfileOverflowing, setIsProfileOverflowing] = useState(false);
const [isReportOverflowing, setIsReportOverflowing] = useState(false);

const getDropdownWidth = (): number => {
  // Query the dropdown trigger element
  const dropdownElement = document.querySelector('m-dropdown-trigger[role="button"]');
  
  if (!dropdownElement) {
    console.warn('Dropdown trigger element not found!');
    return 0;
  }

  // Return the client width or bounding rect width
  return dropdownElement.getBoundingClientRect().width;
};

useEffect(() => {
  const comboboxWidth = getDropdownWidth();

  if (selectedReport?.roleName) {
    setIsProfileOverflowing(isTextOverflowing(selectedReport.roleName, comboboxWidth));
  }

  if (selectedReport?.reportName) {
    setIsReportOverflowing(isTextOverflowing(selectedReport.reportName, comboboxWidth));
  }
}, [selectedReport]);

return (
  <StyledPageContainerDiv>
    <StyledSideMenuDiv className={isCollapsed ? 'collapsed' : ''}>
      <Button
        variant="icon"
        size="large"
        onClick={toggleMenuCollapse}
        style={{ marginLeft: 'calc(100% - 25px)', marginTop: '7%' }}
      >
        {isCollapsed ? (
          <PiArrowLineRightBold size="25" color="#005454" />
        ) : (
          <PiArrowLineLeftBold size="25" color="#005454" />
        )}
      </Button>
      {!isCollapsed && (
        <>
          <StyledLSLogoImg src={LsLogo} alt="LS Logo" />
          {!isSingleRole && (
            <>
              <StyledSubTitleDiv>
                <Text color="color-neutral-60" variant="body-md-bold">
                  PROFILE
                </Text>
              </StyledSubTitleDiv>
              {isProfileOverflowing ? (
                <Tooltip
                  content="Profile"
                  message={selectedReport?.roleName}
                  trigger="hover"
                >
                  <StyledDropDown size="default">
                    <StyledDropdownTrigger>
                      <span className="text">
                        {selectedReport
                          ? selectedReport.roleName.includes(userLogin.email)
                            ? 'Free SG Dashboards'
                            : selectedReport.roleName
                          : 'Select'}
                      </span>
                    </StyledDropdownTrigger>
                    <Dropdown.Menu>
                      {uniqueRoles.map((role, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() =>
                            setSelectedReport(reports.find(report => report.roleName === role))
                          }
                        >
                          {role.includes(userLogin.email)
                            ? `Free SG Report - ${role.split('-').pop()}`
                            : role}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </StyledDropDown>
                </Tooltip>
              ) : (
                <StyledDropDown size="default">
                  <StyledDropdownTrigger>
                    <span className="text">
                      {selectedReport
                        ? selectedReport.roleName.includes(userLogin.email)
                          ? 'Free SG Dashboards'
                          : selectedReport.roleName
                        : 'Select'}
                    </span>
                  </StyledDropdownTrigger>
                  <Dropdown.Menu>
                    {uniqueRoles.map((role, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setShowRefreshButton(false);
                          localStorage.removeItem('fiai_redirect_params');
                          setSelectedReport(reports.find(report => report.roleName === role));
                        }}
                      >
                        {role.includes(userLogin.email)
                          ? `Free SG Report - ${role.split('-').pop()}`
                          : role}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </StyledDropDown>
              )}
            </>
          )}

    <StyledSubTitleDiv>
      <Text color="color-neutral-60" variant="body-md-bold">
        REPORTS
      </Text>
    </StyledSubTitleDiv>
    {isReportOverflowing ? (
      <Tooltip
        content="Report"
        message={selectedReport?.reportName}
        trigger="hover"
      >
        <StyledDropDown size="default">
          <StyledDropdownTrigger>
            <span className="text">
              {selectedReport ? selectedReport.reportName : 'Select'}
            </span>
          </StyledDropdownTrigger>
          <Dropdown.Menu>
            {filteredReports.map((report, index) => (
              <Dropdown.Item
                key={index}
                onClick={() => handleDropdownItemClick(report)}
              >
                {report.reportName}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </StyledDropDown>
      </Tooltip>
    ) : (
      <StyledDropDown size="default">
        <StyledDropdownTrigger>
          <span className="text">
            {selectedReport ? selectedReport.reportName : 'Select'}
          </span>
        </StyledDropdownTrigger>
        <Dropdown.Menu>
          {filteredReports.map((report, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleDropdownItemClick(report)}
            >
              {report.reportName}
            </Dropdown.Item>
          ))}
          </Dropdown.Menu>
      </StyledDropDown>
    )}
        <StyledSubTitleDiv>
          <Text color="color-neutral-60" variant="body-md-bold">
            INTEGRATIONS
          </Text>
        </StyledSubTitleDiv>
        <StyledActionDiv>
          <Text
            color="color-neutral-10"
            variant="body-md-bold"
            onClick={handleIntegrationsOverviewClick}
          >
            Integrations Overview
          </Text>
        </StyledActionDiv>

        <StyledSubTitleDiv>
          <Text color="color-neutral-60" variant="body-md-bold">
            EXPLORE
          </Text>
        </StyledSubTitleDiv>
        <StyledActionDiv>
          <Text
            color="color-neutral-10"
            variant="body-md-bold"
            onClick={handleDemoAllReportsClick}
          >
            Demo All Reports{' '}
            <Icon name="arrow-corner-up" color="color-accent-20" />
          </Text>
        </StyledActionDiv>
        </>
      )}
    </StyledSideMenuDiv>

    <StyledReportWrapper className={isCollapsed ? 'collapsed' : ''}>
      <Header />
      {selectedReport && !showIntegrations && (
        <ReportHeaderComponent
          toggleZendeskDialog={toggleZendeskDialog}
          reportName={selectedReport?.reportName || ''}
        />
      )}
      {showDialogFeedback === DialogFeedbackType.SUCCESS && (
        <BannerWrapper>
          <Banner variant="success" onDismiss={handleDismiss} dismissible>
            <Banner.Title>Success</Banner.Title>
            <Banner.Description>
              Your request has been sent. Soon we'll get in touch.
            </Banner.Description>
          </Banner>
        </BannerWrapper>
      )}
      {showDialogFeedback === DialogFeedbackType.FAILED && (
        <BannerWrapper>
          <Banner variant="danger" onDismiss={handleDismiss} dismissible>
            <Banner.Title>Your request was not sent.</Banner.Title>
            <Banner.Description>
              Please contact Loadsmart team to handle the request.
            </Banner.Description>
          </Banner>
        </BannerWrapper>
      )}
      {selectedReport && !showIntegrations && (
        <ReportComponent
          selectedReport={selectedReport}
          accessToken={pbiToken?.embedToken}
          isReportLoading={isReportsLoading}
          isTokenLoading={isTokenLoading}
          isDefaultReport={selectedReport?.roleName === uniqueRoles[0]}
        />
      )}
      {showRefreshButton && (
        <Banner variant="warning" onDismiss={handleDismiss} dismissible>
        <Banner.Icon><VscGraph color='#005454' size='big' /></Banner.Icon>
        <Banner.Title>This is a subset of data</Banner.Title>
        <Banner.Description>The data on this report was previously filtered by FIAI</Banner.Description>
        <Banner.Actions>
            <Banner.ActionPrimary onClick={handleRefreshReport}>
              Remove preset filters
            </Banner.ActionPrimary>
           
        </Banner.Actions>
    </Banner>
      )}

      {showIntegrations && (
        <IntegrationComponent
          integrations={integrations}
          isLoadingIntegrations={isLoadingIntegrations}
        />
      )}
    </StyledReportWrapper>

    <ZendeskDialog
      open={showRequestDialog}
      requestType={requestType}
      disabled={isSendingRequest}
      onCancelRequest={() => setShowRequestDialog(false)}
      onSendRequest={handleOnSendFeedback}
      setRequestType={setRequestType}
    />
  </StyledPageContainerDiv>
);
}

export default ManagedAnalytics


